//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { of } from 'rxjs';
import { catchError, switchMap, map, tap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { NGXLogger } from 'ngx-logger';
import { OrderService } from '@/_services';
import { OrderValidation, OrderValidationStatus } from '@/_models';

import {
    LoadInStateSimpleOrdersAction, FetchSimpleOrdersAction,
    FetchFailedSimpleOrdersAction, FetchOkSimpleOrdersAction,
    LoadInStateOrderDetailsAction, ClearOrderDetailsAction, FetchOrderDetailsAction,
    FetchOkOrderDetailsAction, FetchFailedOrderDetailsAction,
    UpdateOrderDetailsAction, UpdateOkOrderDetailsAction, UpdateFailedOrderDetailsAction,
    UpdateOrderSectionsAction, UpdateOkOrderSectionsAction, UpdateFailedOrderSectionsAction,
    DeleteOrderAction, DeleteInStateOrderAction, DeleteFailedOrderAction, DeleteOkOrderAction,
    UpdateInStateSectionStatusAction, UpdateSectionStatusAction, UpdateFailedSectionStatusAction, UpdateOkSectionStatusAction,
    LoadInStateMnemonicToolsAction, FetchMnemonicToolsAction, FetchFailedMnemonicToolsAction, FetchOkMnemonicToolsAction,
    LoadInStateOrderMnemonicCatalogAction, FetchOrderMnemonicCatalogAction,
    FetchFailedOrderMnemonicCatalogAction, FetchOkOrderMnemonicCatalogAction, FetchOrderListAction, LoadInStateOrderListAction,
    FetchFailedOrderListAction, FetchOkOrderListAction, CopyOrderAction, LoadInStateCopyOrderAction, CopyOrderOkAction,
    CopyOrderFailedAction, ClearCopyOrderAction, CopySectionAction, LoadInStateCopySectionAction,
    CopySectionOkAction, CopySectionFailedAction, ClearCopySectionAction, LoadInStateOrderValidationMessagesAction,
    ClearOrderValidationMessagesAction, LoadInStateOrderExtendedListAction, FetchOrderExtendedListAction, FetchOkOrderExtendedListAction,
    FetchFailedOrderExtendedListAction, FetchDapDocumentsAction, LoadInStateDapDocumentsAction, FetchOkDapDocumentsAction,
    FetchFailedDapDocumentsAction, SyncOrderAction, SyncOrderFailedAction, SyncOrderOkAction, LoadInStateSyncOrderAction,
    ClearSyncOrderAction,
    FetchTargetWitsmlObjectsAction, LoadInStateTargetWitsmlObjectsAction, FetchOkTargetWitsmlObjectsAction,
    FetchFailedTargetWitsmlObjectsAction,
    FetchTargetWellboresAction, LoadInStateTargetWellboresAction, FetchOkTargetWellboresAction, FetchFailedTargetWellboresAction, 
    FetchActiveWellWellboresAction, LoadInStateActiveWellWellboresAction, FetchOkActiveWellWellboresAction, FetchFailedActiveWellWellboresAction,
    FetchMyRigsAction, FetchOkMyRigsAction, FetchFailedMyRigsAction, LoadInStateMyRigsAction,
    AddMyRigAction, AddOkMyRigAction, AddFailedMyRigAction, DeleteMyRigAction, DeleteOkMyRigAction, DeleteFailedMyRigAction,
    ToggleIsTemplateAction, ToggleIsTemplateOkAction, ToggleIsTemplateFailedAction, LoadInStateIsTemplateAction, UpdateOrderDetailsIsTemplateAction,
    ClearAssetContactsAction,
    LoadInStateAssetContactsAction,
    FetchAssetContactsAction,
    FetchOkAssetContactsAction,
    FetchFailedAssetContactsAction,
} from './order.actions';

@Injectable()
export class OrderEffects {
    
    public onServerFetchSimpleOrders$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSimpleOrdersAction),
        switchMap(() => this.orderService.getOrdersSimple().pipe(
            switchMap(x => [new LoadInStateSimpleOrdersAction(x), new FetchOkSimpleOrdersAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch RealTimeOrders', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSimpleOrdersAction(error));
            })
        ))
    ));

    
    public onServerFetchOrderExtendedList$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchOrderExtendedListAction),
        switchMap(() => this.orderService.getOrdersExtendedList().pipe(
            switchMap(x => [new LoadInStateOrderExtendedListAction(x), new FetchOkOrderExtendedListAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch OrderExtendedList', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedOrderExtendedListAction(error));
            })
        ))
    ));

    
    public onServerDeleteOrder$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteOrderAction),
        switchMap(a => this.orderService.deleteOrder(a.orderId).pipe(
            switchMap(() => [new DeleteInStateOrderAction(a.orderId), new DeleteOkOrderAction()]),
            catchError(error => {
                this.logger.error('Could not Delete Order', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteFailedOrderAction(error));
            })
        ))
    ));

    
    public onServerFetchOrderDetails$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchOrderDetailsAction),
        switchMap(a => this.orderService.getOrderDetails(a.orderId).pipe(
            switchMap(x => [
                new LoadInStateOrderDetailsAction(x), new FetchOkOrderDetailsAction(),
                new ClearCopyOrderAction(), new ClearCopySectionAction(),
            ]),
            catchError(error => {
                this.logger.error('Could not Fetch OrderDetails', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedOrderDetailsAction(error));
            })
        ))
    ));

    
    public onServerFetchOrderList$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchOrderListAction),
        switchMap(x => this.orderService.getOrderList(x.withSections).pipe(
            switchMap(y => [new LoadInStateOrderListAction(y), new FetchOkOrderListAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch OrderList', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedOrderListAction(error));
            })
        ))
    ));

    
    public onServerUpdateOrderDetails$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateOrderDetailsAction),
        switchMap(a => this.orderService.updateOrderDetails(a.order).pipe(
            switchMap(x => {
                let validationStatus: OrderValidationStatus;
                if (x.isValid) {
                    validationStatus = OrderValidationStatus.success;
                    this.alertService.success('The Order was successfully saved!');
                } else if (!x.isValid && x.data) {
                    const msg = getMessageFromError(x);
                    this.logger.warn('Order Validation: ', msg);
                    validationStatus = OrderValidationStatus.warning;
                    this.alertService.warning('The Order was successfully saved with warnings!');
                }

                const orderValidation: OrderValidation = {
                    validationMessages: x.messages,
                    validationStatus: validationStatus
                };

                return [
                    new LoadInStateOrderDetailsAction(x.data),
                    new UpdateOkOrderDetailsAction(),
                    new ClearCopyOrderAction(),
                    new ClearCopySectionAction(),
                    new LoadInStateOrderValidationMessagesAction(orderValidation)
                ];
            }),
            catchError(error => {
                this.logger.error('Could not Update OrderDetails', error);
                this.alertService.error('An error occurred while saving the document!');
                return of(
                    new UpdateFailedOrderDetailsAction(error),
                    new LoadInStateOrderValidationMessagesAction({
                        validationMessages: (
                            error.error && Array.isArray(error.error.messages)
                                ? error.error.messages
                                : [{ message: getMessageFromError(error) }]
                        ),
                        validationStatus: OrderValidationStatus.error
                    })
                );
            })
        ))
    ));

    
    public onServerUpdateOrderSections$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateOrderSectionsAction),
        switchMap(a => this.orderService.updateOrderSections(a.order).pipe(
            switchMap(x => {
                let validationStatus: OrderValidationStatus;
                if (x.isValid) {
                    validationStatus = OrderValidationStatus.success;
                    this.alertService.success('The Order sections ware successfully saved!');
                } else if (!x.isValid && x.data) {
                    const msg = getMessageFromError(x);
                    this.logger.warn('Order Validation: ', msg);
                    validationStatus = OrderValidationStatus.warning;
                    this.alertService.warning('The Order sections ware successfully saved with warnings!');
                }

                const orderValidation: OrderValidation = {
                    validationMessages: x.messages,
                    validationStatus: validationStatus
                };

                return [
                    new LoadInStateOrderDetailsAction(x.data),
                    new UpdateOkOrderSectionsAction(),
                    new ClearCopyOrderAction(),
                    new ClearCopySectionAction(),
                    new LoadInStateOrderValidationMessagesAction(orderValidation)
                ];
            }),
            catchError(error => {
                this.logger.error('Could not Update OrderSections', error);
                this.alertService.error('An error occurred while saving the document!');
                return of(
                    new UpdateFailedOrderSectionsAction(error),
                    new LoadInStateOrderValidationMessagesAction({
                        validationMessages: (
                            error.error && Array.isArray(error.error.messages)
                                ? error.error.messages
                                : [{ message: getMessageFromError(error) }]
                        ),
                        validationStatus: OrderValidationStatus.error
                    })
                );
            })
        ))
    ));

    
    public onOrderValidationMessagesClear$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearOrderValidationMessagesAction),
        map(() => new LoadInStateOrderValidationMessagesAction())
    ));

    
    public onOrderDetailsClear$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearOrderDetailsAction),
        map(() => new LoadInStateOrderDetailsAction())
    ));

    
    public onServerCopyOrder$ = createEffect(() => this.actions$.pipe(
        ofAction(CopyOrderAction),
        switchMap(a => this.orderService.copyOrder(a.params).pipe(
            switchMap(x => [new LoadInStateCopyOrderAction(x, a.params), new CopyOrderOkAction()]),
            catchError(error => {
                this.logger.error('Could not Copy order', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CopyOrderFailedAction(error));
            })
        ))
    ));

    
    public onServerCopySection$ = createEffect(() => this.actions$.pipe(
        ofAction(CopySectionAction),
        switchMap(a => this.orderService.copySection(a.params).pipe(
            switchMap(x => [new LoadInStateCopySectionAction(x, a.params), new CopySectionOkAction()]),
            catchError(error => {
                this.logger.error('Could not Copy section', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CopySectionFailedAction(error));
            })
        ))
    ));

    
    public onLoadAssetContacts$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchAssetContactsAction),
        switchMap(a => this.orderService.getAssetContacts(a.orderId).pipe(
            switchMap(x => [new LoadInStateAssetContactsAction(x), new FetchOkAssetContactsAction()]),
            catchError(error => {
                this.logger.error('Could not get asset contacts', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedAssetContactsAction(error));
            })
        ))
    ));

    
    public onOrderCopyClear$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearCopyOrderAction),
        map(() => new LoadInStateCopyOrderAction())
    ));

    
    public onSectionCopyClear$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearCopySectionAction),
        map(() => new LoadInStateCopySectionAction())
    ));

    
    public onAssetContactsClear$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearAssetContactsAction),
        map(() => new LoadInStateAssetContactsAction())
    ));

    
    public onServerOk$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteOkOrderAction),
        tap(() => this.alertService.success('Update completed.'))
    ), { dispatch: false });

    
    public onServerUpdateSectionStatus$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateSectionStatusAction),
        switchMap(a => this.orderService.updateSectionStatus(a.sectionId, a.status, a.oldStatus).pipe(
            switchMap((x) => {
                if (x.success) {
                    return [new UpdateInStateSectionStatusAction(a.sectionId, a.status, a.statusMap), new UpdateOkSectionStatusAction()];
                }
                this.alertService.warning(x.reason);
                return [new UpdateOkSectionStatusAction()];
            }),
            catchError(error => {
                this.logger.error('Could not Update SectionStatus', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateFailedSectionStatusAction(error));
            })
        ))
    ));

    
    public onServerFetchMnemonicTools$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchMnemonicToolsAction),
        switchMap(x => this.orderService.getTools(x.orderId).pipe(
            switchMap(y => [new LoadInStateMnemonicToolsAction(y), new FetchOkMnemonicToolsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch MnemonicTools', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedMnemonicToolsAction(error));
            })
        ))
    ));

    
    public onServerFetchOrderMnemonicCatalog$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchOrderMnemonicCatalogAction),
        switchMap(() => this.orderService.getMnemonicCatalog().pipe(
            switchMap(x => [new LoadInStateOrderMnemonicCatalogAction(x), new FetchOkOrderMnemonicCatalogAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch OrderMnemonicCatalog', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedOrderMnemonicCatalogAction(error));
            })
        ))
    ));

    
    public onServerFetchDapDocuments$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentsAction),
        switchMap(x => this.orderService.getDapDocuments(x.dapDocumentId).pipe(
            switchMap(y => [new LoadInStateDapDocumentsAction(y), new FetchOkDapDocumentsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch DapDocuments', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedDapDocumentsAction(error));
            })
        ))
    ));

    
    public onServerSyncOrder$ = createEffect(() => this.actions$.pipe(
        ofAction(SyncOrderAction),
        switchMap(a => this.orderService.syncOrderWithDapDocument(a.dapDocumentId).pipe(
            switchMap(x => [new LoadInStateSyncOrderAction(x), new SyncOrderOkAction()]),
            catchError(error => {
                this.logger.error('Could not Sync order', error);
                this.alertService.error(getMessageFromError(error));
                return of(new SyncOrderFailedAction(error));
            })
        ))
    ));

    public onServerFetchTargetWitsmlObjects$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchTargetWitsmlObjectsAction),
        switchMap(x => this.orderService.loadTargetWells(x.targetId).pipe(
            switchMap(y => [new LoadInStateTargetWitsmlObjectsAction(y), new FetchOkTargetWitsmlObjectsAction(),
                new FetchActiveWellWellboresAction(x.targetId)]),
            catchError(error => {
                this.logger.error('Could not Fetch Witsml Target Objects', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedTargetWitsmlObjectsAction(error));
            })
        ))
    ));

    public onServerFetchActiveWellWellbores$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchActiveWellWellboresAction),
        switchMap(x => this.orderService.loadActiveWellWellbores(x.targetId).pipe(
            switchMap(y => [new LoadInStateActiveWellWellboresAction(y), new FetchOkActiveWellWellboresAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Active Wellbores', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedActiveWellWellboresAction(error));
            })
        ))
    ));

    public onServerFetchTargetWellbores$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchTargetWellboresAction),
        switchMap(x => this.orderService.loadTargetWellbores(x.targetId, x.wellUid).pipe(
            switchMap(y => [new LoadInStateTargetWellboresAction(x.wellUid, y), new FetchOkTargetWellboresAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Witsml Target Wellbores', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedTargetWellboresAction(error));
            })
        ))
    ));
    
    public onOrderSyncClear$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearSyncOrderAction),
        map(() => new LoadInStateSyncOrderAction())
    ));
    
    public onFetchMyRigs$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchMyRigsAction),
        switchMap(() => this.orderService.getMyRigs().pipe(
            switchMap(x => [new LoadInStateMyRigsAction(x), new FetchOkMyRigsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch My Rigs', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedMyRigsAction(error));
            })
        ))
    ));
    
    public onAddMyRig$ = createEffect(() => this.actions$.pipe(
        ofAction(AddMyRigAction),
        switchMap(x => this.orderService.addMyRig(x.ridId).pipe(
            switchMap(x => [new LoadInStateMyRigsAction(x), new AddOkMyRigAction()]),
            catchError(error => {
                this.logger.error('Could not Add My Rig', error);
                this.alertService.error(getMessageFromError(error));
                return of(new AddFailedMyRigAction(error));
            })
        ))
    ));
    
    public onDeleteMyRig$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteMyRigAction),
        switchMap(x => this.orderService.deleteMyRig(x.ridId).pipe(
            switchMap(x => [new LoadInStateMyRigsAction(x), new DeleteOkMyRigAction()]),
            catchError(error => {
                this.logger.error('Could not Delete My Rig', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteFailedMyRigAction(error));
            })
        ))
    ));

    public onToggleIsTemplate$ = createEffect(() => this.actions$.pipe(
        ofAction(ToggleIsTemplateAction),
        switchMap((a) => this.orderService.toggleIsTemplate(a.orderId).pipe(
            switchMap(x => a.isToggledFromOrderDetails
                ? [new UpdateOrderDetailsIsTemplateAction(a.orderId, x), new ToggleIsTemplateOkAction()]
                : [new LoadInStateIsTemplateAction(a.orderId, x), new ToggleIsTemplateOkAction()]),
            catchError(error => {
                this.logger.error('Could not Toggle IsTemplate', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ToggleIsTemplateFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly orderService: OrderService,
    ) { }
}
