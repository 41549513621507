// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export class Role {
    public id: number;
    public roleName: string;
    public hasServiceProvider?: boolean;
}

export enum Roles {
    SystemAdmin = 1,
    OrderManager = 2,
    OperatorUser = 3,
    ServiceCoAdmin = 4,
    ServiceCoUser = 5,
    SubscriberUser = 6,
    ServiceCoOrderManager = 7,
    ChangeManager = 8,
    // DapAdmin = 9,
    DapManager = 10,
    DapAuthor = 11,
    DapScorer = 12,
    DapReader = 13,
    SurveillanceSupportEngineer = 14,
    //SurveillanceEngineer = 15,
    DapProvider = 16,
    SuperUser = 17,
}
