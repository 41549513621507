// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ActiveSectionVM } from '@/_models';
import { environment } from '../../environments/environment';
import { PublicPart } from 'ngx-shared';
import { ApplianceJobVM, ApplianceServiceVM } from '@/_models/appliance-common';
import { ApplianceFileVM } from '@/_models/appliance-common/appliance-file-vm';
import { ApplianceFileDetailsVM } from '@/_models/appliance-common/appliance-file-details-vm';

@Injectable({
    providedIn: 'root'
})
export class TransferManagementService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<ActiveSectionVM[]> {
        return this.http.get<ActiveSectionVM[]>(`${environment.portalWebApi}/TransferManagement`);
    }

    getJobs(slot: number): Observable<ApplianceJobVM[]> {
        return this.http.get<ApplianceJobVM[]>(`${environment.portalWebApi}/TransferManagement/jobs/${slot}`).pipe(
            tap(x => x && x.filter(item => item.lastUpdateTime)
                .forEach(item => {
                    item.lastUpdateTime = new Date(item.lastUpdateTime);
                    if (item?.data?.activationDate) {
                        //set '+00:00' to format properly the Date (timezone to be added)
                        item.data.activationDate = new Date(item.data.activationDate + '+00:00');
                    }
                }))
        );
    }

    getServices(slot: number): Observable<ApplianceServiceVM[]> {
        return this.http.get<ApplianceServiceVM[]>(`${environment.portalWebApi}/TransferManagement/services/${slot}`).pipe(
            tap(x => x && x.filter(item => item.lastUpdate).forEach(item => item.lastUpdate = new Date(item.lastUpdate)))
        );
    }

    getTransferFiles(slot: number): Observable<ApplianceFileVM[]> {
        return this.http.get<ApplianceFileVM[]>(`${environment.portalWebApi}/TransferManagement/configFiles/${slot}`);
    }

    getTransferFileDetails(applianceId: number, fileNames: string[]): Observable<ApplianceFileDetailsVM[]> {
        return this.http.get<ApplianceFileDetailsVM[]>(`${environment.portalWebApi}/TransferManagement/${applianceId}/fileDetails?${fileNames.map(x => `fileNames=${x}`).join('&')}`);
    }

    runServiceCommand(slot: number, serviceId: string, commandName: string): Observable<ApplianceServiceVM> {
        return this.http.get<ApplianceServiceVM>(`${environment.portalWebApi}/TransferManagement/services/${slot}/${serviceId}/${commandName}`);
    }
}

/* eslint-disable */
export class FakeTransferManagementService implements PublicPart<TransferManagementService> {
    getAll(): Observable<ActiveSectionVM[]> {
        throw new Error('Method not implemented.');
    }

    getJobs(slot: number): Observable<ApplianceJobVM[]> {
        throw new Error('Method not implemented.');
    }

    getServices(slot: number): Observable<ApplianceServiceVM[]> {
        throw new Error('Method not implemented.');
    }
    getTransferFiles(slot: number): Observable<ApplianceFileVM[]> {
        throw new Error('Method not implemented.');
    }

    getTransferFileDetails(applianceId: number, fileNames: string[]): Observable<ApplianceFileDetailsVM[]> {
        throw new Error('Method not implemented.');
    }

    runServiceCommand(slot: number, serviceId: string, commandName: string): Observable<ApplianceServiceVM> {
        throw new Error('Method not implemented.');
    }
}
/* eslint-enable */
