// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { CompressionMethod } from './compression-method';
import { WebSocketClientLibrary } from './web-socket-client-library';
import { WebSocketSubProtocol } from './web-socket-sub-protocol';
import { WebSocketEncoding } from './web-socket-encoding';

export class ServerConnection {
    public id: number;
    public name: string;
    public shortName: string;
    public isActive: boolean;
    public allowTargetRuleOverride: boolean;
    public isDapTarget: boolean;
    public pollingInterval: number;
    public maxCurvesPerTransfer: number;
    public logDataFromDta: boolean;

    public inActiveSection: boolean;

    public soap: SoapConnection;

    public etp: EtpConnection;

    public support: ConnectionSupport;

    public serviceProviderId: number;
}

class BaseConnection {
    public id: number;
    public url: string;
    public username: string;
    public password: string;

    public isAvailable: boolean;
    public isAvailableLastChangeTimestamp: Date;

    public proxyHost?: string;
    public proxyPort?: number;
    public proxyUsername?: string;
    public proxyPassword?: string;

    public tls1_0: boolean;
    public tls1_1: boolean;
    public tls1_2: boolean;
    public acceptInvalidCertificates: boolean;
}

export class SoapConnection extends BaseConnection {
    public protocol: string;

    public compression: CompressionMethod;
    public acceptCompressedServerResponses: boolean;
}

export class EtpConnection extends BaseConnection {
    /** is ETP connection used */
    public isDisabled: boolean;
    public clientLibrary: WebSocketClientLibrary;
    public subProtocol: WebSocketSubProtocol;
    public encoding: WebSocketEncoding;
    public compression: CompressionMethod;
}

export class ConnectionSupport {
    public email: string;
    public emailCc?: string;
}
