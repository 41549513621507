//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
// TODO Fix Circular dependency - importing directly from '@_helpers' leads to Circular dependency
// also in kpi.service.ts
import { formatDateInNonIsoFormat } from '@/_helpers/non-iso-date-formatter';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';
import {
    MnemonicTool, Order, OrderSectionCurve, OrderSimple, SectionStatus, ServiceResult, OrderListItem, CopyOrderParams,
    CopySectionParams, OrderSection, OrderWithExtendedData, MonitorOverviewUpdate, IdNamePair, WitsmlObject,
    OrderAsset
} from '@/_models';
import { DapOrder } from '@/_models/dap-order-sync/dap-order';

export class SectionStatusChangeResult {
    public success: boolean;
    public reason: string;
}

@Injectable({ providedIn: 'root' })
export class OrderService {
    constructor(private http: HttpClient) { }

    getOrdersSimple(): Observable<OrderSimple[]> {
        return this.http.get<OrderSimple[]>(`${environment.portalWebApi}/OrdersManagement`).pipe(
            tap(x => x && x.filter(item => item.orderStartDate).forEach(item => item.orderStartDate = new Date(item.orderStartDate)))
        );
    }

    getOrdersExtendedList(): Observable<OrderWithExtendedData[]> {
        return this.http.get<OrderWithExtendedData[]>(`${environment.portalWebApi}/OrdersManagement/extended`).pipe(
            tap(x => x && x.filter(item => item.orderStartDate).forEach(item => item.orderStartDate = new Date(item.orderStartDate)))
        );
    }

    deleteOrder(orderId: number): Observable<void> {
        return this.http.delete<void>(`${environment.portalWebApi}/OrdersManagement/delete/${orderId}`);
    }

    getOrderDetails(orderId: number): Observable<Order> {
        return this.http.get<Order>(`${environment.portalWebApi}/OrdersManagement/${orderId}`).pipe(
            tap(x => {
                if (x) {
                    if (x.startDateRig) {
                        x.startDateRig = new Date(x.startDateRig);
                    }
                    if (x.creationDate) {
                        x.creationDate = new Date(x.creationDate);
                    }

                    if (Array.isArray(x.sections)) {
                        x.sections.filter(item => item.startDateRig).forEach(item => item.startDateRig = new Date(item.startDateRig));
                        x.sections.filter(item => item.endDateRig).forEach(item => item.endDateRig = new Date(item.endDateRig));
                        x.sections.filter(item => item.activatedDate).forEach(item => item.activatedDate = new Date(item.activatedDate));
                        x.sections.filter(item => item.completedDate).forEach(item => item.completedDate = new Date(item.completedDate));
                        x.sections.forEach(s => {
                            if (Array.isArray(s.depthRanges)) {
                                s.depthRanges.filter(item => item.forcedUntilTimeRig).forEach(item => item.forcedUntilTimeRig = new Date(item.forcedUntilTimeRig));
                            }
                        });
                    }
                }
            })
        );
    }

    updateOrderDetails(order: Order): Observable<ServiceResult<Order>> {
        order = manuallyFormatDates(order);

        return this.http.post<ServiceResult<Order>>(`${environment.portalWebApi}/OrdersManagement/save`, order).pipe(
            tap(x => {
                if (x && x.data) {
                    if (x.data.startDateRig) {
                        x.data.startDateRig = new Date(x.data.startDateRig);
                    }
                    if (x.data.creationDate) {
                        x.data.creationDate = new Date(x.data.creationDate);
                    }

                    if (Array.isArray(x.data.sections)) {
                        x.data.sections.filter(item => item.startDateRig).forEach(item => item.startDateRig = new Date(item.startDateRig));
                        x.data.sections.filter(item => item.endDateRig).forEach(item => item.endDateRig = new Date(item.endDateRig));
                        x.data.sections.filter(item => item.activatedDate).forEach(item => item.activatedDate = new Date(item.activatedDate));
                        x.data.sections.filter(item => item.completedDate).forEach(item => item.completedDate = new Date(item.completedDate));
                        x.data.sections.forEach(s => {
                            if (Array.isArray(s.depthRanges)) {
                                s.depthRanges.filter(item => item.forcedUntilTimeRig).forEach(item => item.forcedUntilTimeRig = new Date(item.forcedUntilTimeRig));
                            }
                        });
                    }
                }
            })
        );
    }

    updateOrderSections(order: Order): Observable<ServiceResult<Order>> {
        order = manuallyFormatDates(order);

        return this.http.post<ServiceResult<Order>>(`${environment.portalWebApi}/OrdersManagement/updateCurvesAndTools`, order).pipe(
            tap(x => {
                if (x && x.data) {
                    if (x.data.startDateRig) {
                        x.data.startDateRig = new Date(x.data.startDateRig);
                    }
                    if (x.data.creationDate) {
                        x.data.creationDate = new Date(x.data.creationDate);
                    }

                    if (Array.isArray(x.data.sections)) {
                        x.data.sections.filter(item => item.startDateRig).forEach(item => item.startDateRig = new Date(item.startDateRig));
                        x.data.sections.filter(item => item.endDateRig).forEach(item => item.endDateRig = new Date(item.endDateRig));
                        x.data.sections.filter(item => item.activatedDate).forEach(item => item.activatedDate = new Date(item.activatedDate));
                        x.data.sections.filter(item => item.completedDate).forEach(item => item.completedDate = new Date(item.completedDate));
                        x.data.sections.forEach(s => {
                            if (Array.isArray(s.depthRanges)) {
                                s.depthRanges.filter(item => item.forcedUntilTimeRig).forEach(item => item.forcedUntilTimeRig = new Date(item.forcedUntilTimeRig));
                            }
                        });
                    }
                }
            })
        );
    }

    updateSectionStatusWithState(sectionId: number, newStatus: SectionStatus, oldStatus: SectionStatus): Observable<MonitorOverviewUpdate> {
        return this.updateSectionStatus(sectionId, newStatus, oldStatus)
            .pipe(switchMap(() => (of({ sectionId, newState: newStatus } as MonitorOverviewUpdate))));
    }

    updateSectionStatus(sectionId: number, newStatus: SectionStatus, oldStatus: SectionStatus): Observable<SectionStatusChangeResult> {
        switch (newStatus) {
            case SectionStatus.Draft:
                if (oldStatus === SectionStatus.Ready) {
                    return this.setStatus(sectionId, 'reset');
                } else {
                    return this.setStatus(sectionId, 'draft');
                }
            case SectionStatus.Ready:
                if (oldStatus === SectionStatus.Verified || oldStatus === SectionStatus.Active) {
                    return this.setStatus(sectionId, 'reset');
                } else {
                    return this.setStatus(sectionId, 'ready');
                }
            case SectionStatus.Verified:
                return this.setStatus(sectionId, 'verify');
            case SectionStatus.Active:
                return this.setStatus(sectionId, 'activate');
            case SectionStatus.Completed:
                return this.setStatus(sectionId, 'complete');
            default:
                return EMPTY;
        }
    }

    getTools(orderId: number): Observable<MnemonicTool[]> {
        return this.http.get<MnemonicTool[]>(`${environment.portalWebApi}/OrdersManagement/mnemonictools/${orderId}`);
    }

    getMnemonicCatalog(): Observable<OrderSectionCurve[]> {
        return this.http.get<OrderSectionCurve[]>(`${environment.portalWebApi}/OrdersManagement/mnemoniccatalog`);
    }
    
    getAssetContacts(orderId: number): Observable<OrderAsset[]> {
        return this.http.get<OrderAsset[]>(`${environment.portalWebApi}/OrdersManagement/assetcontacts/${orderId}`);
    }

    getOrderList(withSections = false): Observable<OrderListItem[]> {
        return this.http.get<OrderListItem[]>(
            `${environment.portalWebApi}/OrdersManagement/${withSections ? 'orderSectionList' : 'orderList'}`
        );
    }

    copyOrder(params: CopyOrderParams): Observable<Order> {
        return this.http.post<Order>(`${environment.portalWebApi}/OrdersManagement/copy`, params).pipe(
            tap(x => {
                if (x) {
                    if (x.startDateRig) {
                        x.startDateRig = new Date(x.startDateRig);
                    }
                    if (x.creationDate) {
                        x.creationDate = new Date(x.creationDate);
                    }

                    if (Array.isArray(x.sections)) {
                        x.sections.filter(item => item.startDateRig).forEach(item => item.startDateRig = new Date(item.startDateRig));
                        x.sections.filter(item => item.endDateRig).forEach(item => item.endDateRig = new Date(item.endDateRig));
                        x.sections.filter(item => item.activatedDate).forEach(item => item.activatedDate = new Date(item.activatedDate));
                        x.sections.filter(item => item.completedDate).forEach(item => item.completedDate = new Date(item.completedDate));
                        x.sections.forEach(s => {
                            if (Array.isArray(s.depthRanges)) {
                                s.depthRanges.filter(item => item.forcedUntilTimeRig).forEach(item => item.forcedUntilTimeRig = new Date(item.forcedUntilTimeRig));
                            }
                        });
                    }
                }
            })
        );
    }

    copySection(params: CopySectionParams): Observable<OrderSection> {
        return this.http.post<OrderSection>(`${environment.portalWebApi}/Sections/copy`, params).pipe(
            tap(x => {
                if (x) {
                    if (x.startDateRig) { x.startDateRig = new Date(x.startDateRig); }
                    if (x.endDateRig) { x.endDateRig = new Date(x.endDateRig); }
                    if (x.activatedDate) { x.activatedDate = new Date(x.activatedDate); }
                    if (x.completedDate) { x.completedDate = new Date(x.completedDate); }
                    if (Array.isArray(x.depthRanges)) {
                        x.depthRanges.filter(item => item.forcedUntilTimeRig).forEach(item => item.forcedUntilTimeRig = new Date(item.forcedUntilTimeRig));
                    }
                }
            })
        );
    }

    getDapDocuments(dapDocumentId: number): Observable<IdNamePair[]> {
        return this.http.get<IdNamePair[]>(`${environment.portalWebApi}/OrdersManagement/dapDocuments/${dapDocumentId}`);
    }

    syncOrderWithDapDocument(documentId: number): Observable<DapOrder> {
        return this.http.get<DapOrder>(`${environment.portalWebApi}/OrdersManagement/dapDocumentSync/${documentId}`).pipe(
            tap(x => {
                if (x) {
                    if (x.startDateRig) {
                        x.startDateRig = new Date(x.startDateRig);
                    }

                    if (Array.isArray(x.sections)) {
                        x.sections.filter(item => item.startDateRig).forEach(item => item.startDateRig = new Date(item.startDateRig));
                        x.sections.filter(item => item.endDateRig).forEach(item => item.endDateRig = new Date(item.endDateRig));
                    }
                }
            })
        );
    }

    loadTargetWells(targetId: number): Observable<WitsmlObject[]> {
        return this.http.get<WitsmlObject[]>(`${environment.portalWebApi}/OrdersManagement/targetWells/${targetId}`);
    }

    loadActiveWellWellbores(targetId: number): Observable<WitsmlObject[]> {
        return this.http.get<WitsmlObject[]>(`${environment.portalWebApi}/OrdersManagement/activeWellWellbores/${targetId}`);
    }

    loadTargetWellbores(targetId: number, wellUid: string): Observable<WitsmlObject[]> {
        return this.http.post<WitsmlObject[]>(`${environment.portalWebApi}/OrdersManagement/targetWellWellbores/${targetId}`, { stringValue: wellUid });
    }

    getMyRigs(): Observable<number[]> {
        return this.http.get<number[]>(`${environment.portalWebApi}/OrdersManagement/myRigs`);
    }

    addMyRig(rigId: number): Observable<number[]> {
        return this.http.post<number[]>(`${environment.portalWebApi}/OrdersManagement/addMyRig/${rigId}`, {});
    }

    deleteMyRig(rigId: number): Observable<number[]> {
        return this.http.delete<number[]>(`${environment.portalWebApi}/OrdersManagement/deleteMyRig/${rigId}`);
    }

    toggleIsTemplate(orderId: number): Observable<boolean> {
        return this.http.get<boolean>(`${environment.portalWebApi}/OrdersManagement/${orderId}/istemplate/toggle`);
    }

    private setStatus(sectionId: number, suffix: string): Observable<SectionStatusChangeResult> {
        return this.http.get<SectionStatusChangeResult>(`${environment.portalWebApi}/Sections/${sectionId}/${suffix}`);
    }
}

/**
 * Manually serialize the date strings to avoid the auto conversion from user's local tz to UTC
 * the aim is to send the date as defined in the UI and to do the conversion on the back end
 * @param order
 */
function manuallyFormatDates(order: Order) {
    if (order) {
        order = { ...order };
        order.startDateRig = formatDateInNonIsoFormat(order.startDateRig);
        order.creationDate = formatDateInNonIsoFormat(order.creationDate);

        if (Array.isArray(order.sections)) {
            order.sections = order.sections.map(x => {
                const result = { ...x };
                result.startDateRig = formatDateInNonIsoFormat(result.startDateRig);
                result.endDateRig = formatDateInNonIsoFormat(result.endDateRig);
                result.activatedDate = formatDateInNonIsoFormat(result.activatedDate);
                result.completedDate = formatDateInNonIsoFormat(result.completedDate);
                if (Array.isArray(result.depthRanges)) {
                    result.depthRanges = result.depthRanges.map(range => {
                        const newRange = { ...range };
                        newRange.forcedUntilTimeRig = formatDateInNonIsoFormat(newRange.forcedUntilTimeRig);
                        return newRange;
                    })
                }
                return result;
            });
        }
    }

    return order;
}

/* eslint-disable */
export class FakeOrderService implements PublicPart<OrderService> {
    getOrdersSimple(): Observable<OrderSimple[]> {
        throw new Error('Method not implemented.');
    }

    getOrdersExtendedList(): Observable<OrderWithExtendedData[]> {
        throw new Error('Method not implemented.');
    }

    deleteOrder(orderId: number): Observable<void> {
        throw new Error('Method not implemented.');
    }

    getOrderDetails(orderId: number): Observable<Order> {
        throw new Error('Method not implemented.');
    }

    updateOrderDetails(order: Order): Observable<ServiceResult<Order>> {
        throw new Error('Method not implemented.');
    }

    updateOrderSections(order: Order): Observable<ServiceResult<Order>> {
        throw new Error('Method not implemented.');
    }

    updateSectionStatusWithState(sectionId: number, newStatus: SectionStatus, oldStatus: SectionStatus): Observable<any>  {
        throw new Error('Method not implemented.');
    }

    updateSectionStatus(sectionId: number, newStatus: SectionStatus, oldStatus: SectionStatus): Observable<SectionStatusChangeResult> {
        throw new Error('Method not implemented.');
    }

    getTools(): Observable<MnemonicTool[]> {
        throw new Error('Method not implemented.');
    }

    getMnemonicCatalog(): Observable<OrderSectionCurve[]> {
        throw new Error('Method not implemented.');
    }
    
    getAssetContacts(orderId: number): Observable<OrderAsset[]> {
        throw new Error('Method not implemented.');
    }

    getOrderList(): Observable<OrderListItem[]> {
        throw new Error('Method not implemented.');
    }

    copyOrder(params: CopyOrderParams): Observable<Order> {
        throw new Error('Method not implemented.');
    }

    copySection(params: CopySectionParams): Observable<OrderSection> {
        throw new Error('Method not implemented.');
    }

    getDapDocuments(): Observable<IdNamePair[]> {
        throw new Error('Method not implemented.');
    }

    syncOrderWithDapDocument(documentId: number): Observable<DapOrder> {
        throw new Error('Method not implemented.');
    }

    loadTargetWells(targetId: number): Observable<WitsmlObject[]> {
        throw new Error('Method not implemented.');
    }

    loadActiveWellWellbores(targetId: number): Observable<WitsmlObject[]> {
        throw new Error('Method not implemented.');
    }

    loadTargetWellbores(targetId: number, wellUid: string): Observable<WitsmlObject[]> {
        throw new Error('Method not implemented.');
    }

    getMyRigs(): Observable<number[]> {
        throw new Error('Method not implemented.');
    }

    addMyRig(rigId: number): Observable<number[]> {
        throw new Error('Method not implemented.');
    }

    deleteMyRig(rigId: number): Observable<number[]> {
        throw new Error('Method not implemented.');
    }
    
    toggleIsTemplate(orderId: number): Observable<boolean> {
        throw new Error('Method not implemented.');
    }
}
/* eslint-enable */
