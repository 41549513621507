//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import {
    MnemonicTool, Order, OrderSectionCurve, OrderSimple, SectionStatus, OrderListItem, CopyOrderParams,
    CopySectionParams, OrderSection, OrderValidation, OrderWithExtendedData, IdNamePair, WitsmlObject,
    OrderAsset,
} from '@/_models';
import { DapOrder } from '@/_models/dap-order-sync/dap-order';
import { Action } from '@ngrx/store';

export class LoadInStateSimpleOrdersAction implements Action {
    public type = '[Order] Load in state SimpleOrders action';

    constructor(public readonly orders: OrderSimple[]) { }
}

//#region async Fetch SimpleOrders

export class FetchSimpleOrdersAction implements Action {
    public type = '[Order] Fetch SimpleOrders action';
}
export class FetchOkSimpleOrdersAction implements Action {
    public type = '[Order] Fetch OK SimpleOrders action';
}
export class FetchFailedSimpleOrdersAction implements Action {
    public type = '[Order] Fetch Fail SimpleOrders action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch SimpleOrders

export class ClearOrderDetailsAction implements Action {
    public type = '[Order] Clear in state OrderDetails action';
}

export class LoadInStateOrderDetailsAction implements Action {
    public type = '[Order] Load in state OrderDetails action';

    constructor(public readonly order?: Order) { }
}

//#region async Delete Order

export class DeleteOrderAction implements Action {
    public type = '[Order] Delete Order action';
    constructor(public readonly orderId: number) { }
}
export class DeleteOkOrderAction implements Action {
    public type = '[Order] Delete OK Order action';
}
export class DeleteFailedOrderAction implements Action {
    public type = '[Order] Delete Fail Order action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Delete Order

export class DeleteInStateOrderAction implements Action {
    public type = '[Order] Delete in state Order action';
    constructor(public readonly orderId: number) { }
}

//#region async Fetch OrderDetails

export class FetchOrderDetailsAction implements Action {
    public type = '[Order] Fetch OrderDetails action';
    constructor(public readonly orderId: number) { }
}
export class FetchOkOrderDetailsAction implements Action {
    public type = '[Order] Fetch OK OrderDetails action';
}
export class FetchFailedOrderDetailsAction implements Action {
    public type = '[Order] Fetch Fail OrderDetails action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch OrderDetails

//#region async Update OrderDetails

export class UpdateOrderDetailsAction implements Action {
    public type = '[Order] Update OrderDetails action';
    constructor(public readonly order: Order) { }
}
export class UpdateOkOrderDetailsAction implements Action {
    public type = '[Order] Update OK OrderDetails action';
}
export class UpdateFailedOrderDetailsAction implements Action {
    public type = '[Order] Update Fail OrderDetails action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Update OrderDetails

//#region async Update OrderSections

export class UpdateOrderSectionsAction implements Action {
    public type = '[Order] Update OrderSections action';
    constructor(public readonly order: Order) { }
}
export class UpdateOkOrderSectionsAction implements Action {
    public type = '[Order] Update OK OrderSections action';
}
export class UpdateFailedOrderSectionsAction implements Action {
    public type = '[Order] Update Fail OrderSections action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Update OrderSections

export class UpdateInStateSectionStatusAction implements Action {
    public type = '[Order] Update in state SectionStatus action';
    constructor(public readonly sectionId: number, public readonly status: SectionStatus,
        public readonly statusMap: Map<number, string>
    ) { }
}

//#region async Update SectionStatus

export class UpdateSectionStatusAction implements Action {
    public type = '[Order] Update SectionStatus action';
    constructor(public readonly sectionId: number, public readonly status: SectionStatus,
        public readonly oldStatus: SectionStatus,
        public readonly statusMap: Map<number, string>
    ) { }
}
export class UpdateOkSectionStatusAction implements Action {
    public type = '[Order] Update OK SectionStatus action';
}
export class UpdateFailedSectionStatusAction implements Action {
    public type = '[Order] Update Fail SectionStatus action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Update SectionStatus

export class LoadInStateMnemonicToolsAction implements Action {
    public type = '[Order] Load in state MnemonicTools action';

    constructor(public readonly tools: MnemonicTool[]) { }
}

//#region async Fetch MnemonicTools

export class FetchMnemonicToolsAction implements Action {
    public type = '[Order] Fetch MnemonicTools action';
    constructor(public readonly orderId: number) { }
}
export class FetchOkMnemonicToolsAction implements Action {
    public type = '[Order] Fetch OK MnemonicTools action';
}
export class FetchFailedMnemonicToolsAction implements Action {
    public type = '[Order] Fetch Fail MnemonicTools action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch AssetContacts

export class LoadInStateAssetContactsAction implements Action {
    public type = '[Order] Load in state AssetContacts action';

    constructor(public readonly assetContacts?: OrderAsset[]) { }
}

//#region async Fetch AssetContacts

export class FetchAssetContactsAction implements Action {
    public type = '[Order] Fetch AssetContacts action';
    constructor(public readonly orderId: number) { }
}
export class FetchOkAssetContactsAction implements Action {
    public type = '[Order] Fetch OK AssetContacts action';
}
export class FetchFailedAssetContactsAction implements Action {
    public type = '[Order] Fetch Fail AssetContacts action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch AssetContacts

export class LoadInStateOrderMnemonicCatalogAction implements Action {
    public type = '[Order] Load in state OrderMnemonicCatalog action';

    constructor(public readonly catalog: OrderSectionCurve[]) { }
}

//#region async Fetch OrderMnemonicCatalog

export class FetchOrderMnemonicCatalogAction implements Action {
    public type = '[Order] Fetch OrderMnemonicCatalog action';
}
export class FetchOkOrderMnemonicCatalogAction implements Action {
    public type = '[Order] Fetch OK OrderMnemonicCatalog action';
}
export class FetchFailedOrderMnemonicCatalogAction implements Action {
    public type = '[Order] Fetch Fail OrderMnemonicCatalog action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch OrderMnemonicCatalog

export class LoadInStateOrderListAction implements Action {
    public type = '[Order] Load in state OrderList action';
    constructor(public readonly orderList: OrderListItem[]) { }
}

export class FetchOrderListAction implements Action {
    public type = '[Order] Fetch OrderList action';
    constructor(public readonly withSections = false) { }
}

export class FetchOkOrderListAction implements Action {
    public type = '[Order] Fetch OK OrderList action';
}

export class FetchFailedOrderListAction implements Action {
    public type = '[Order] Fetch Fail OrderList action';
    constructor(public readonly error: Error | string | any) { }
}

//#region async Copy order

export class LoadInStateCopyOrderAction implements Action {
    public type = '[Order] Load in state copy order action';
    constructor(public readonly order?: Order, public readonly params?: CopyOrderParams) { }
}

export class CopyOrderAction implements Action {
    public type = '[Order] Copy order action';
    constructor(public readonly params: CopyOrderParams) { }
}

export class CopyOrderOkAction implements Action {
    public type = '[Order] Copy OK order action';
}

export class CopyOrderFailedAction implements Action {
    public type = '[Order] Copy Fail order action';
    constructor(public readonly error: Error | string | any) { }
}

export class ClearCopyOrderAction implements Action {
    public type = '[Order] Clear copy order action';
}

export class ClearAssetContactsAction implements Action {
    public type = '[Order] Clear order asset contacts';
}

//#endregion async Copy order

//#region Copy section

export class LoadInStateCopySectionAction implements Action {
    public type = '[Order] Load in state copy section action';
    constructor(public readonly section?: OrderSection, public readonly params?: CopySectionParams) { }
}

export class CopySectionAction implements Action {
    public type = '[Order] Copy section action';
    constructor(public readonly params: CopySectionParams) { }
}

export class CopySectionOkAction implements Action {
    public type = '[Order] Copy section OK action';
}

export class CopySectionFailedAction implements Action {
    public type = '[Order] Copy section fail action';
    constructor(public readonly error: Error | string | any) { }
}

export class ClearCopySectionAction implements Action {
    public type = '[Order] Clear copy section action';
}

//#endregion Copy section
//#region Load OrderValidationMessages

export class LoadInStateOrderValidationMessagesAction implements Action {
    public type = '[Order] Load in state OrderValidationMessages action';

    constructor(public readonly orderValidation?: OrderValidation) { }
}

export class ClearOrderValidationMessagesAction implements Action {
    public type = '[Order] Clear in stateOrderValidationMessages action';
}

//#endregion Load OrderValidationMessages

export class LoadInStateOrderExtendedListAction implements Action {
    public type = '[Order] Load in state OrderExtendedList action';

    constructor(public readonly orders?: OrderWithExtendedData[]) { }
}

//#region async Fetch OrderExtendedList

export class FetchOrderExtendedListAction implements Action {
    public type = '[Order] Fetch OrderExtendedList action';
}
export class FetchOkOrderExtendedListAction implements Action {
    public type = '[Order] Fetch OK OrderExtendedList action';
}
export class FetchFailedOrderExtendedListAction implements Action {
    public type = '[Order] Fetch Fail OrderExtendedList action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch OrderExtendedList

//#region Fetch DapDocuments

export class LoadInStateDapDocumentsAction implements Action {
    public type = '[Order] Load in state DapDocuments action';
    constructor(public readonly dapDocuments: IdNamePair[]) { }
}

export class FetchDapDocumentsAction implements Action {
    public type = '[Order] Fetch DapDocuments action';
    constructor(public readonly dapDocumentId: number) { }
}

export class FetchOkDapDocumentsAction implements Action {
    public type = '[Order] Fetch OK DapDocuments action';
}

export class FetchFailedDapDocumentsAction implements Action {
    public type = '[Order] Fetch Fail DapDocuments action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Fetch DapDocuments

//#region Sync with DAP

export class LoadInStateSyncOrderAction implements Action {
    public type = '[Order] Load in state sync order action';
    constructor(public readonly order?: DapOrder) { }
}

export class SyncOrderAction implements Action {
    public type = '[Order] Sync order action';
    constructor(public readonly dapDocumentId: number) { }
}

export class SyncOrderOkAction implements Action {
    public type = '[Order] Sync OK order action';
}

export class SyncOrderFailedAction implements Action {
    public type = '[Order] Sync Fail order action';
    constructor(public readonly error: Error | string | any) { }
}

export class ClearSyncOrderAction implements Action {
    public type = '[Order] Clear sync order action';
}

//#endregion Sync with DAP

//#region Fetch Target Witsml Objects

export class LoadInStateTargetWitsmlObjectsAction implements Action {
    public type = '[Order] Load in state Target Witsml Objects action';
    constructor(public readonly targetWitsmlObjects: WitsmlObject[]) { }
}

export class FetchTargetWitsmlObjectsAction implements Action {
    public type = '[Order] Fetch Target Witsml Objects action';
    constructor(public readonly targetId: number) { }
}

export class FetchOkTargetWitsmlObjectsAction implements Action {
    public type = '[Order] Fetch OK Target Witsml Objects action';
}

export class FetchFailedTargetWitsmlObjectsAction implements Action {
    public type = '[Order] Fetch Fail Target Witsml Objects action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadInStateActiveWellWellboresAction implements Action {
    public type = '[Order] Load in state Active Well Wellbores action';
    constructor(public readonly activeWellWellbores: WitsmlObject[]) { }
}

export class FetchActiveWellWellboresAction implements Action {
    public type = '[Order] Fetch Active Well Wellbores action';
    constructor(public readonly targetId: number) { }
}

export class FetchOkActiveWellWellboresAction implements Action {
    public type = '[Order] Fetch OK Active Well Wellbores action';
}

export class FetchFailedActiveWellWellboresAction implements Action {
    public type = '[Order] Fetch Fail Active Well Wellbores action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadInStateTargetWellboresAction implements Action {
    public type = '[Order] Load in state Target Wellbores action';
    constructor(public readonly wellUid: string, public readonly targetWellbores: WitsmlObject[]) { }
}

export class FetchTargetWellboresAction implements Action {
    public type = '[Order] Fetch Target Wellbores action';
    constructor(public readonly targetId: number, public readonly wellUid: string) { }
}

export class FetchOkTargetWellboresAction implements Action {
    public type = '[Order] Fetch OK Target Wellbores action';
}

export class FetchFailedTargetWellboresAction implements Action {
    public type = '[Order] Fetch Fail Target Wellbores action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Fetch Target Witsml Objects

//#region My Rigs

export class LoadInStateMyRigsAction implements Action {
    public type = '[Order] Load in state My Rigs action';
    constructor(public readonly myRigs: number[]) { }
}

export class FetchMyRigsAction implements Action {
    public type = '[Order] Fetch My Rigs action';
}

export class FetchOkMyRigsAction implements Action {
    public type = '[Order] Fetch OK My Rigs action';
}

export class FetchFailedMyRigsAction implements Action {
    public type = '[Order] Fetch Fail My Rigs action';
    constructor(public readonly error: Error | string | any) { }
}

export class AddMyRigAction implements Action {
    public type = '[Order] Add My Rig action';
    constructor(public readonly ridId: number) { }
}

export class AddOkMyRigAction implements Action {
    public type = '[Order] Add OK My Rig action';
}

export class AddFailedMyRigAction implements Action {
    public type = '[Order] Add Fail My Rig action';
    constructor(public readonly error: Error | string | any) { }
}

export class DeleteMyRigAction implements Action {
    public type = '[Order] Delete My Rig action';
    constructor(public readonly ridId: number) { }
}

export class DeleteOkMyRigAction implements Action {
    public type = '[Order] Delete OK My Rig action';
}

export class DeleteFailedMyRigAction implements Action {
    public type = '[Order] Delete Fail My Rig action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion My Rigs

//#region IsTemplate

export class ToggleIsTemplateAction implements Action {
    public type = '[Order] Toggle IsTemplate action';
    constructor(public readonly orderId: number, public readonly isToggledFromOrderDetails = false) { }
}

export class ToggleIsTemplateOkAction implements Action {
    public type = '[Order] Toggle IsTemplate Ok action';
}

export class ToggleIsTemplateFailedAction implements Action {
    public type = '[Order] Toggle IsTemplate Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadInStateIsTemplateAction implements Action {
    public type = '[Order] Load in state IsTemplate action';

    constructor(public readonly orderId: number, public readonly isTemplate: boolean) { }
}

export class UpdateOrderDetailsIsTemplateAction implements Action {
    public type = '[Order] Update Order details IsTemplate action';

    constructor(public readonly orderId: number, public readonly isTemplate: boolean) { }
}

//#endregion IsTemplate