// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { RigState } from '../rig-state';
import { SectionStatus } from './section-status';
export class MonitorOverview {
    public targetId: number;
    public wellId: string;
    public wellBoreId: string;
    public wellBoreName: string;
    public orderId: number;
    public sectionName: string;
    public sectionId: number;
    public sectionType: string;
    public status: SectionStatus;
    public rigOrInstallationId: number;
    public rigName: string;
    public partnerName: string;
    public fieldName: string;
    public mwdProvider: string;
    public mlProvider: string;
    public isTightWell: boolean;
    public startDepth: number;
    public startDepthUoM: string;
    public targetDepth: number;
    public targetDepthUoM: string;
    public startDate: Date;
    public rigState: RigState;
    public rigStateTimeIndex: Date;
    public rigLocalTime: Date;
    public holeDepth: number;
    public holeDepthUom: string;
    public holeDepthTimeIndex: Date;
    public bitDepth: number;
    public bitDepthUom: string;
    public bitDepthTimeIndex: Date;
}

export class MonitorOverviewUpdate {
    public sectionId: number;
    public newState: SectionStatus;
}
